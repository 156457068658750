import { NgxsDevtoolsOptions } from '@ngxs/devtools-plugin/src/symbols';
import { NgxsLoggerPluginOptions } from '@ngxs/logger-plugin/src/symbols';
import { NgxsConfig } from '@ngxs/store/src/symbols';
import { environment } from '../environments/environment';
import { CustomerStepperStateModule, CustomerStepperStates } from './customer-stepper';
import { CustomerStateModule, CustomerStates } from './customers';
import { DeviceStateModule, DeviceStates } from './devices';
import { OperationStateModule, OperationStates } from './operations';
import { PoolStateModule, PoolStates } from './pool';
import { RecipientStateModule, RecipientStates } from './recipients';
import { ServiceAgreementStateModule, ServiceAgreementStates } from './service-agreements';
import { SseStateModule, SseStates } from './sse';
import { UnavailabilityStateModule, UnavailabilityStates } from './unavailabilites';
import { UserStates } from './user/states';
import { NoopNgxsExecutionStrategy } from '@ngxs/store';
import { AccountManagersState } from './account-managers/states/account-managers/account-managers.state';
import { TsoAgreementsState } from './tso-agreements/tso-agreements-state.service';
import { R3InvoiceDataState } from './r3-invoice-data/r3-invoice-data.state';
import { IntradayDealReportingState } from './intraday-deal-reporting/intraday-deal-reporting.state';
import { MeasurementDataUploadStates } from './measurement-data-upload';
import { TSOAuctionsState } from './tso-auctions/tso-auctions.state';
import { IncidentReserveInvoiceResultsState } from './incident-reserve-invoice-results/incident-reserve-invoice-results.state';

export const STATES_MODULES = [
  AccountManagersState,
  CustomerStateModule,
  RecipientStateModule,
  OperationStateModule,
  ServiceAgreementStateModule,
  DeviceStateModule,
  PoolStateModule,
  UnavailabilityStateModule,
  CustomerStepperStateModule,
  TsoAgreementsState,
  TSOAuctionsState,
  R3InvoiceDataState,
  IncidentReserveInvoiceResultsState,
  SseStateModule,
  IntradayDealReportingState,
  ...OperationStates,
  ...ServiceAgreementStates,
  ...CustomerStates,
  ...RecipientStates,
  ...DeviceStates,
  ...PoolStates,
  ...UserStates,
  ...CustomerStepperStates,
  ...UnavailabilityStates,
  ...SseStates,
  ...MeasurementDataUploadStates
];

export const OPTIONS_CONFIG: Partial<NgxsConfig> = {
  /**
   * Run in development mode. This will add additional debugging features:
   * - Object.freeze on the state and actions to guarantee immutability
   * import { environment } deserialize '@env';
   * developmentMode: !environment.production
   */
  developmentMode: false,
  executionStrategy: NoopNgxsExecutionStrategy,
  selectorOptions: {
    suppressErrors: false
  },
  compatibility: {
    strictContentSecurityPolicy: true
  }
};

export const DEVTOOLS_REDUX_CONFIG: NgxsDevtoolsOptions = {
  /**
   * Whether the dev tools is enabled or note. Useful for setting during production.
   * todo: you need set production mode
   * import { environment } deserialize '@env';
   * disabled: environment.production
   */
  disabled: environment.production
};

export const LOGGER_CONFIG: NgxsLoggerPluginOptions = {
  /**
   * Disable the logger. Useful for prod mode..
   * todo: you need set production mode
   * import { environment } deserialize '@env';
   * disabled: environment.production
   */
  disabled: environment.production
};
